var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("c-btn", {
        attrs: { label: "up", showLoading: false },
        on: { btnClicked: _vm.sizeUp },
      }),
      _c("c-btn", {
        attrs: { label: "down", showLoading: false },
        on: { btnClicked: _vm.sizeDown },
      }),
      _c(
        "div",
        {
          ref: "parent",
          style: {
            height: _vm.parentHeight,
            transform: "scale(" + _vm.scale + ", " + _vm.scale + ")",
          },
          attrs: { id: "parent" },
        },
        [
          _c(
            "VueDraggableResizable",
            {
              ref: "markImage",
              attrs: {
                "class-name-dragging": "my-dragging-class",
                "class-name": "my-class-fp1",
                x: 20,
                y: 20,
                w: 100,
                h: 100,
                grid: [20, 20],
              },
            },
            [
              _c("div", {
                staticClass: "rotate",
                on: { mouseenter: _vm.rotateEvent, mouseleave: _vm.rotateStop },
              }),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }