<template>
  <div>
    <c-btn label="up" :showLoading="false" @btnClicked="sizeUp" />
    <c-btn label="down" :showLoading="false" @btnClicked="sizeDown" />
    <div id="parent" ref="parent"
      :style="{height: parentHeight, transform: 'scale(' + scale + ', ' + scale + ')'}">
      <VueDraggableResizable
        ref="markImage"
        class-name-dragging="my-dragging-class" class-name="my-class-fp1"
        :x="20"
        :y="20"
        :w="100" :h="100"
        :grid="[20, 20]"
      >
      <div class="rotate" 
        @mouseenter="rotateEvent"
        @mouseleave="rotateStop" />
      </VueDraggableResizable>
    </div>
  </div>
</template>

<script>
import VueDraggableResizable from 'vue-draggable-resizable'
import 'vue-draggable-resizable/dist/VueDraggableResizable.css'
import selectConfig from '@/js/selectConfig';
export default {
  name: 'floor-plan',
  components: { VueDraggableResizable },
  data() {
    return {
      searchParam: {
        plantCd: null,
        useFlag: 'Y',
      },
      current: null,
      clientX: 0,
      clientY: 0,
      parentHeight: '',
      scale: 1,
      editable: true,
      listUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
    window.addEventListener('resize', this.setSize);
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.setSize);
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.listUrl = selectConfig.ram.matrix.list.url
      // code setting
      // list setting


      this.setSize();  

      // this.getList();
    },
    setImage() {
      this.setCanvas(20)
    },
    // setCanvas(canvas, ctx, size) {
    setCanvas(size) {
      let parent = document.getElementById('parent');
      parent.style.cssText  = 'background: linear-gradient(-90deg, rgba(0, 0, 0, 0.1) 1px, transparent 1px) 0% 0% / ' 
        + size + 'px ' + size + 'px, linear-gradient(rgba(0, 0, 0, 0.1) 1px, transparent 1px) 0% 0% / ' + size + 'px ' + size + 'px '
        + ', url("' + require('@/assets/images/정수장 개요도면_2.png') + '") no-repeat left/contain;'
        + 'overflow:auto; transform-origin: left top;';
    },
    setSize() {
      this.parentHeight = String(window.innerHeight - 100) + 'px'

      this.setImage();
    },
    sizeUp() {
      this.scale += 0.1;
    },
    sizeDown() {
      this.scale -= 0.1;
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    /* eslint-disable no-unused-vars */
    rotateEvent(e) {
      let getBorderBox = (target) => {
        let _hoverLine = target.cloneNode();
        _hoverLine.innerHTML = '';
        _hoverLine.style.cssText = target.style.cssText;
        _hoverLine.style.opacity = '0';
        _hoverLine.style.pointerEvents = 'none';
        let transform = _hoverLine.style.transform;
        if (transform && transform.indexOf('rotate') >= 0) {
          _hoverLine.style.transform = transform.replace(/rotate\(\w*\)/gi, '');
        } else if (target.angle) {
          _hoverLine.style.transform = transform + ' rotate(0deg)';
        }
        target.parentNode.appendChild(_hoverLine);
        let bBox = _hoverLine.getBoundingClientRect();
        _hoverLine.remove();
        return bBox;
      };
      let bBox = getBorderBox(e.target);
      let mark = this.$refs['markImage'].$el
      let arrowRects = mark.getBoundingClientRect();
      this.clientX = arrowRects.left + arrowRects.width / 2;
      this.clientY = arrowRects.top + arrowRects.height / 2;
      let radians = Math.atan2(e.clientY - this.clientY, e.clientX - this.clientX);
      let deg = radians * (180 / Math.PI) + 20;
      // mark.style.left = parseInt(bBox.left + window.scrollX) + 'px';
      // mark.style.top = parseInt(bBox.top + window.scrollY) + 'px';
      // mark.style.width = parseInt(bBox.width + 2) + 'px';
      // mark.style.height = parseInt(bBox.height + 2) + 'px';
      let originTran = mark.style.transform;
      mark.style.webkitTransform = 'rotate(' + deg + 'deg)';
      mark.style.mozTransform = 'rotate(' + deg + 'deg)';
      mark.style.msTransform = 'rotate(' + deg + 'deg)';
      mark.style.oTransform = 'rotate(' + deg + 'deg)';
      mark.style.transform = originTran + ' rotate(' + deg + 'deg)';
      // let rect = mark.getBoundingClientRect();
      // parent = mark.parentNode;
      // if (parent.classList.contains('drag-drop')) {
      //   parent.style.width = rect.width;
      //   parent.style.height = rect.height;
      // }
    },
    rotateStop() {
      this.current = null;
      this.clientX = 0;
      this.clientY = 0;
    }
  }
};
</script>
<style>
.my-class-fp1 {
  /* background-color: #9E9E9E; */
  border: 3px dashed #FF5722;
  -webkit-transition: background-color 200ms linear;
  -ms-transition: background-color 200ms linear;
  transition: background-color 200ms linear;
}

.my-dragging-class {
  background-color: #FF5722;
  border: 3px dashed #FF5722;
}

.image {
  position: absolute;
  top: 80px;
}

.rotate {
  position: absolute;
  bottom: -30px;
  left: calc(50% - 10px);
  width: 20px;
  height: 20px;
  border-radius: 50%;
  cursor: pointer;
  pointer-events: all;
  background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDE5LjAuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9Ii03NCA3NiA1MCA1MCIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAtNzQgNzYgNTAgNTA7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4KPHN0eWxlIHR5cGU9InRleHQvY3NzIj4KCS5zdDB7ZmlsbDojRkZGRkZGO30KCS5zdDF7ZmlsbDojMTc3NEI3O30KPC9zdHlsZT4KPGNpcmNsZSBpZD0iWE1MSURfM18iIGNsYXNzPSJzdDAiIGN4PSItNDkiIGN5PSIxMDEiIHI9IjI0LjkiLz4KPHBhdGggY2xhc3M9InN0MSIgZD0iTS00OSwxMTUuOWMtMy44LDAtNy42LTEuNS0xMC40LTQuMWMtMi45LTIuOC00LjYtNi41LTQuNy0xMC41Yy0wLjEtNCwxLjMtNy44LDQuMS0xMC43YzIuOS0zLDYuOC00LjcsMTAuOS00LjcKCWMzLDAsNS45LDAuOSw4LjMsMi41di0xLjRjMC0xLjMsMS0yLjMsMi4zLTIuM2MxLjMsMCwyLjMsMSwyLjMsMi4zdjYuNWMwLDEtMC43LDEuOS0xLjUsMi4ybC02LjQsMi4xYy0wLjMsMC4xLTAuNSwwLjEtMC43LDAuMQoJYy0xLDAtMS45LTAuNy0yLjItMS41Yy0wLjItMC42LTAuMi0xLjIsMC0xLjhjMC4zLTAuNiwwLjgtMSwxLjQtMS4xbDIuOC0xYy0xLjgtMS40LTQuMS0yLjItNi40LTIuMmMtMC42LDAtMS4yLDAuMS0xLjgsMC4yCgljLTUuOCwxLTkuNyw2LjUtOC43LDEyLjNjMC45LDUuMSw1LjMsOC45LDEwLjQsOC45YzAuNiwwLDEuMy0wLjEsMS44LTAuMmMyLjgtMC41LDUuMy0yLDYuOS00LjNjMS43LTIuNCwyLjMtNS4yLDEuOC03LjkKCWMtMC4xLTAuNiwwLTEuMiwwLjQtMS44YzAuNC0wLjUsMC44LTAuOCwxLjUtMC45bDAuMSwwaDAuM2MxLjEsMCwyLjEsMC44LDIuMiwxLjljMC44LDQuNy0wLjcsOS40LTMuOSwxMi45CglDLTQxLDExNC4zLTQ0LjgsMTE1LjktNDksMTE1Ljl6Ii8+Cjwvc3ZnPgo=);
  box-shadow: 0px 0px 3px black;
  background-color: rgba(255, 255, 255, 1);
}
</style>